@use "shared" as *;

.download-form {
    display: flex;
    flex-direction: column;
    flex-basis: 50%;
    position: relative;
}

.download-form__note {
    position: relative;
    margin: 20px 20px 0px 20px;
    padding: 16px;
    display: flex;
    gap: 16px;
    background-color: $coconut-gray-color;
    user-select: none;
}

.download-form__note-icon {
    width: 22px;
    height: 22px;
    flex-shrink: 0;
}

.download-form__note-text {
    @include table;
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 770px;
    margin-top: 6px;
}

.download-form__note-resize {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
}

.download-form__global-forms {
    margin: 11px 20px 0px 0px;
    display: flex;
}

.download-form__accuracy-form {
    width: 315px;
    height: 56px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    gap: 10px;
    background-color: $white-color;
}

.download-form__accuracy-form-title {
    @include body-mono;
}

.download-form__accuracy-form-select {
    width: 56px;
    height: 10px;
    transform: translate(0px, -1.5px);

    & .select-form__input {
        outline: none;
        height: 10px;
        align-items: normal;
    }

    & .select-form__arrow-container {
        width: 10px;
        height: 10px;
    }

    & .select-form__value {
        margin: 0px;
    }

    & .select-form__options {
        bottom: -11px;
        border-top: 1px solid #dad9d9;
        width: 122px;
    }
}

.download-form__scene-form {
    position: relative;
    background-color: $white-color;
    width: 241px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-left: 2px;
}

.download-form__scene-form-title {
    @include body-mono;
}

.download-form__scene-form-select {
    width: 79px;
    height: 10px;
    transform: translate(0px, -1.5px);

    & .select-form__input {
        outline: none;
        height: 10px;
        align-items: normal;
    }

    & .select-form__arrow-container {
        width: 10px;
        height: 10px;
    }

    & .select-form__value {
        margin: 0px;
    }

    & .select-form__options {
        bottom: -11px;
        border-top: 1px solid #dad9d9;
        width: 122px;
    }
}

.download-form__top-bar {
    display: flex;
    margin-top: 12px;
}

.download-form__top-bar-option {
    @include body-mono-reg;
    color: #00000066;
    height: 24px;
    border-bottom: 1px solid $neutral-gray-color;
    cursor: pointer;

    &_selected {
        color: $black-color;
        border-color: $black-color;
    }

    &_errored {
        color: $error-color;
    }
}

.download-form__top-bar-random-button {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 4px;
    border-bottom: 1px solid $neutral-gray-color;
    cursor: pointer;
}

.download-form__top-bar-random-button-icon {
    transform: translate(0px, -1px);
}

.download-form__top-bar-gap {
    height: 24px;
    border-bottom: 1px solid $neutral-gray-color;
    max-width: 44px;
    flex-grow: 1;
}

.download-form__materials-and-context {
    background-color: $white-color;
    padding: 0px 20px;
    overflow: hidden;
    flex-grow: 1;
}

.download-form__top-bar-border {
    height: 24px;
    border-bottom: 1px solid $neutral-gray-color;
    flex-grow: 1;
}

.download-form__footer {
    display: flex;
    align-items: center;
    margin-bottom: 23px;
}

.download-form__footer-high-light-button {
    padding: 20px;
    background-color: $white-color;
}

.download-form__footer-prototype-button {
    gap: 12px;

    &:hover .black-fill {
        fill: black;
    }

    &>* {
        filter: none;
    }
}

.download-form__footer-big-black-button {
    position: relative;
    gap: 20px;
}

.download-form__note-tooltip {
    position: absolute;
    width: 295px;
    height: 155px;
    background-color: $black-color;
    padding: 20px;
    transform: translate(-100%, 0px);
    left: -10px;
    top: -10px;
    z-index: 9;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.download-form__note-tooltip-arrow {
    position: absolute;
    width: 12px;
    height: 12px;
    background-color: $black-color;
    transform: translate(0px, -50%) rotate(45deg);
    right: -5px;
    top: 50%;
}

.download-form__note-tooltip-text {
    @include body-mono;
    color: $white-color;
}

.download-form__note-tooltip-bottom {
    display: flex;
    align-items: center;
}

.download-form__note-tooltip-bottom-checkbox {
    margin-left: 24px;
}

.download-form__note-tooltip-checkbox-label {
    @include table;
    color: $white-color;
    margin-left: 8px;
    transform: translate(0px, 1px);
}

.download-form__footer-rezognize-high-light-button {
    padding: 10px;
    background-color: $white-color;
    transform: translate(-20px, 0px);
}