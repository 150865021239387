@use "shared" as *;

.list_wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-basis: 57%;
}

.list {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0);
    transition: background-color 0.5s ease;
  }
}

.list_scrolling {
  &::-webkit-scrollbar-thumb {
    background-color: #c2c1c166;
    opacity: 1;
    transition: opacity 0.5s ease;
  }
}

.list_tools {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  padding: 0 11px 0px 20px;
}

.list__toolbar {
  display: flex;
  align-items: center;
  padding: 20px 0;
  background-color: $white-color;
}

.list__toolbar-button {
  @include tools;
  display: flex;
  gap: 8px;
  padding: 8px;
  align-items: center;
  cursor: pointer;
  flex-shrink: 0;

  &:not(&_disabled):not(&_selected):hover {
    background-color: $coconut-gray-color;
  }

  &_disabled {
    opacity: 0.2;
    pointer-events: none;
  }

  &_selected {
    filter: brightness(0) saturate(100%) invert(20%) sepia(69%) saturate(3670%) hue-rotate(207deg) brightness(102%) contrast(97%);
  }
}

.list__toolbar-gap {
  width: 36px;
}

.list__toolbar-sizer {
  flex-grow: 1;
}

.list__table {
  position: relative;
  margin: 0 11px 0 20px;
}

.list__table-elements-wrapper {
  height: 100%;
  position: relative;
}

.list__head {
  display: grid;
  grid-template-columns: 58px 54px 1fr;
  height: 28px;
}

.list__head-hash-cell {
  @include caption;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  background-color: #000;
  border-right: 1px solid rgba(144, 138, 130, 0.5);
  color: $gray-color;
}

.list__head-checkbox-cell {
  display: flex;
  align-items: center;
  padding: 0px 10px;
  justify-content: flex-end;
  background-color: #000;
  border-right: 1px solid rgba(144, 138, 130, 0.5);
}

.list__head-definition-cell {
  @include caption;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0px 10px;
  background-color: #000;
  color: $gray-color;
}

.render__button {
  cursor: pointer;
  border: none;
  display: flex;
  height: 40px;
  padding: 10px 22px 9px 18px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  @include tools;
  color: $white-color;
  background-color: $black-color;
  transition-duration: 200ms;
}

.render__button_icon {
  background-image: url("./icons/render.svg");
  background-size: 24px 19px;
  width: 24px;
  height: 19px;
  margin-bottom: 4px;
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(306deg) brightness(105%) contrast(101%);
}

.render__button:hover {
  color: $black-color;
  background-color: $gray-color;

  .render__button_icon {
    filter: invert(0%) sepia(1%) saturate(4%) hue-rotate(320deg) brightness(97%) contrast(104%);
  }
}

.recognizing__background {
  position: absolute;
  inset: 0px;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 75%, rgba(255, 255, 255, 0) 100%);
}

.recognizing__loader {
  background-image: url(./icons/loader.gif);
  width: 70px;
  height: 70px;
  background-size: contain;
}

.recognizing__number {
  @include table;
  position: absolute;
  top: 0;
  width: 32px;
  height: 32px;
  background-color: $gray-color;
  display: flex;
  align-items: center;
  justify-content: center;

  &_disabled {
    background-color: $light-gray-color !important;
  }
}
