@use "shared" as *;

.personal-settings {
    display: flex;
    gap: 52px;
    margin-top: 37px;
    flex-grow: 1;
}

.personal-settings__details {
    display: flex;
    flex-basis: 50%;
    flex-direction: column;
}

.personal-settings__details-title {
    @include body-mono;
    letter-spacing: -0.26px;
    color: #00000066;
    height: 32px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $gray-color;
}

.personal-settings__form {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.personal-settings__form-checkbox {
    @include body-mono-reg;
    display: flex;
    align-items: center;
    gap: 8px;
}

.personal-settings__form-label {
    @include body-mono;
    letter-spacing: -0.26px;
}

.personal-settings__email-form-container {
    position: relative;
    display: flex;
    gap: 8px;
}

.personal-settings__email-input {
    flex-grow: 1;
}

.personal-settings__email-note {
    @include table;
    position: absolute;
    bottom: -22px;
}

.personal-settings__delete-account-button {
    cursor: pointer;
    margin-top: 38px;
    display: flex;
    align-items: center;
    gap: 2px;
    align-self: baseline;
}

.personal-settings__delete-account-text {
    @include body-mono;
    letter-spacing: -0.26px;
    transform: translate(0px, 1px);
}

.personal-settings__deleting-account {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 52px;
}

.personal-settings__deleting-account-question {
    font-family: "Simplon Mono";
    text-transform: none;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
}

.personal-settings__deleting-account-options {
    display: flex;
    gap: 10px;
}

.personal-settings__early-access {
    position: relative;
    font-family: "Moderat";
    text-transform: none;
    line-height: 136%;
    letter-spacing: -0.56px;
    font-weight: 400;
    width: 400px;
    color: $black-color;
    margin-top: 53px;
    padding: 32px;
    background-color: #F5F5F5;
    display: flex;
    flex-direction: column;
    gap: 24px;
    border-radius: 20px 20px 20px 0px;
}

.personal-settings__early-access-button {
    font-family: "Simplon Mono";
    text-transform: none;
    font-weight: 400;
    font-size: 13px;
    line-height: 135%;
    letter-spacing: -0.26px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;

    &:hover .personal-settings__early-access-button-icon {
        background-color: $black-color;
        color: $gray-color;
    }
}

.personal-settings__early-access-button-icon {
    font-family: "Moderat";
    text-transform: uppercase;
    font-size: 11px;
    line-height: 108%;
    letter-spacing: -0.22px;
    font-weight: 400;
    filter: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 17px;
    background-color: $gray-color;
    color: $black-color;
}

.personal-settings__early-access-requested-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex-grow: 1;
    padding-bottom: 20px;
}

.personal-settings__early-access-requested {
    position: relative;
    display: flex;
    width: 400px;
    padding: 32px;
    flex-direction: column;
    gap: 17px;
    border-radius: 20px 20px 20px 0px;
    background: #F5F5F5;
}

.personal-settings__early-access-d-avatar {
    position: absolute;
    left: -45px;
    bottom: 0px;
}

.personal-settings__early-access-requested-success {
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $black-color;
}

.personal-settings__early-access-requested-content {
    display: flex;
    align-items: center;
    gap: 4px;
}

.personal-settings__early-access-requested-text {
    font-family: "Moderat";
    font-size: 14px;
    text-transform: none;
    font-weight: 400;
    line-height: 136%;
    letter-spacing: -0.56px;
}

.personal-settings__early-access-requested-icon {
    font-family: "Moderat";
    font-size: 11px;
    font-weight: 400;
    line-height: 108%;
    letter-spacing: -0.22px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 17px;
    background-color: $gray-color;
}