@use "shared" as *;

.group {
  display: grid;
  position: relative;
  grid-template-columns: 58px 54px 31px 1fr 40px 42px 36px;
  background-color: $white-color;
  grid-template-rows: 56px 1fr auto;

  &:hover {
    background-color: $light-blue-color;
  }

  &:focus-within .group__cell9,
  &_border-bottom:focus-within .group__cell3 {
    border-bottom: 1px solid $black-color;
  }

  &_is-being-dragged {
    background-color: $light-blue-color;
    opacity: 0.6;
  }

  &_active,
  &_active:hover,
  &_active &_is-being-dragged {
    background-color: $coconut-gray-color;
  }

  &_hidden {
    display: none;
  }

  &_border-bottom {
    border-bottom: 1px solid $medium-gray-color;
    ;
  }

  &:hover .group__read-only-container {
    display: flex;
  }
}

.group__cell1 {
  display: flex;
  align-items: center;
  gap: 9px;
}

.group__drag-handler-container {
  width: 22px;
  height: 22px;
  transform: translate(5px, 0px);
}

.group__cell2 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.group__open-segments {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  width: 50px;
  height: 32px;
  cursor: pointer;

  &:not(&_disabled):hover {
    background-color: $gray-color;
  }

  &_disabled {
    opacity: 0.2;
    cursor: default;
  }
}

.group__open-button {
  &_opened {
    transform: rotate(180deg);
  }
}

.group__cell3 {
  @include table;
  display: flex;
  align-items: center;
  padding: 23px 10px 16px 10px;
  grid-row: 1 / 3;
  grid-column: 4 / 4;
  cursor: text;
}

.group__description {
  @include table;
  width: 100%;
  resize: none;
  outline: none;
  border: none;
  background: transparent;

  &::placeholder {
    @include table;
    color: $gray-color;
  }

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: $medium-gray-color;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
}

.group__cell4 {
  padding: 0px 2px;
  display: flex;
  align-items: center;
}

.group__open-attachments {
  width: 44px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  cursor: pointer;

  &:hover {
    background-color: $gray-color;
  }
}

.group__cell4-triangle {
  transform: translate(0px, -1.5px);

  &_opened {
    transform: translate(0px, -1.5px) rotate(180deg);
  }
}

.group__cell5 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.group__add-attach-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  cursor: pointer;

  &:not(&_disabled):hover {
    background-color: $gray-color;
  }

  &_disabled {
    opacity: 0.2;
    cursor: default;
  }
}

.group__cell6 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.group__ungroup-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  cursor: pointer;

  &:not(&_disabled):hover {
    background-color: $gray-color;
  }

  &_disabled {
    opacity: 0.2;
    cursor: default;
    pointer-events: none;
  }
}

.group__bottom_cell {
  grid-row: 3 / 3;
  border-bottom: 1px solid $medium-gray-color;
}

.group__cell9 {
  align-self: start;
  padding: 0px 10px 16px 10px
}

.group__approve {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
  transform: translate(0px, -7px);
}

.group__approve-cancel-button {
  background-color: transparent;
}

.group__done-button {
  gap: 7px;
}

.group__read-only-container {
  grid-row: 1/ 2;
  grid-column: 4 / 5;
  padding: 0px 10px;
  display: none;
  align-items: center;
  justify-content: flex-end;
}

.group__read-only-background {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  width: 280px;
  background: linear-gradient(90deg, rgba(243, 252, 255, 0.00) 0%, rgba(243, 252, 255, 0.80) 49.66%, #F3FCFF 100%);
}

.group__read-only {
  @include table;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 6px;
  height: 100%;
}

.segments-of-group {
  overflow: hidden;
  transition: height 200ms ease-out;
}

.group__attachments {
  overflow: hidden;
  transition: height 200ms ease-out;
}

.group-segment__container {
  &_dragging {
    background-color: $light-blue-color;
    opacity: 0.6;
  }

  &_active {
    background-color: $coconut-gray-color;
  }

  &_hover {
    background-color: $light-blue-color;
  }

  &_hover .group-segment__background-hover {
    background-color: rgba(4, 99, 225, 0.10);
  }

  &_hover:not(&_opened) .group-segment__guard-container {
    display: flex;
  }
}

.group-segment {
  position: relative;
  display: grid;
  grid-template-columns: 58px 54px 54px 1fr 40px 78px;
  border-bottom: 1px solid $medium-gray-color;
  grid-template-rows: 57px 1fr;
}

.group-segment__cell1 {
  position: relative;
  display: flex;
  align-items: center;
  gap: 9px;
  grid-row: 1 / 3;
  grid-column: 1 / 2;
}

.group-segment__drag-handler-container {
  width: 22px;
  height: 22px;
  transform: translate(5px, 0px);
}

.group-segment__cell2 {
  display: flex;
  align-items: center;
  padding: 9px;
  grid-row: 1 / 3;
  grid-column: 2 / 3;
}

.group-segment__warning-icon {
  position: relative;
  width: 18px;
  height: 18px;
  background-image: url("./icons/warning.svg");
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;

  &:hover {
    background-image: url("./icons/gray-warning.svg");
  }
}

.group-segment__cell3 {
  @include table;
  display: flex;
  align-items: center;
  line-height: normal;
  grid-row: 1 / 3;
  grid-column: 3 / 4;
}

.group-segment__color {
  width: 10px;
  height: 10px;
  margin: 0px 4px 0px 10px;
  border-radius: 50%;
  cursor: pointer;

  &_active {
    border: 2px solid $blue-color;
    box-sizing: content-box;
    margin: 0px 2px 0px 8px;
  }
}

.group-segment__cell4 {
  padding: 12px 10px;
  display: flex;
  align-items: center;
  background-color: $light-gray-color;
  cursor: text;
  grid-row: 1 / 3;
  grid-column: 4 / 5;
}

.group-segment__description {
  @include table;
  padding: 0;
  width: 100%;
  resize: none;
  outline: none;
  border: none;
  background: transparent;
  cursor: text;
  color: $gray-color;

  &::placeholder {
    @include table;
    color: $gray-color;
  }

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: $medium-gray-color;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
}

.group-segment__cell5 {
  background-color: $light-gray-color;
  padding: 0px 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  grid-row: 1 / 3;
  grid-column: 5 / 6;
}

.group-segment__cell5-triangle {
  cursor: pointer;
  transform: translate(0px, -1.5px);

  &_opened {
    transform: translate(0px, -1.5px) rotate(180deg);
  }
}

.group-segment__cell6 {
  grid-column: 6 / 7;
  background-color: $light-gray-color;
}

.group-segment__guard-container {
  grid-row: 1 / 3;
  grid-column: 4 / 5;
  display: none;
  align-items: center;
  justify-content: flex-end;
}

.group-segment__guard {
  @include table;
  color: #616566;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  width: 280px;
  height: 100%;
  background: linear-gradient(90deg, rgba(219, 237, 252, 0.00) 0%, rgba(219, 237, 252, 0.80) 49.66%, #DBEDFC 100%);
}

.group-segment__guard-child {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.group-segment__last-cell {
  grid-row: 2 / 3;
  grid-column: 6 / 7;
  background-color: $light-gray-color;
}

.group-segment__attachments {
  overflow: hidden;
  transition: height 200ms ease-out;
}