@use "shared" as *;

.phoneInput_container {
  border: 1px solid $neutral-gray-color;
  height: 40px;
}

.phoneInput_input {
  @include table;
  color: $black-color;
  font-size: 13px !important;
  outline: none !important;
  border: none !important;
  height: 38px;
  width: 100% !important;
  border-radius: 0px !important;

  &::placeholder {
    @include table;
    color: $gray-color;
  }

  &:focus {
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15) !important;
    border: none;
  }
}

.phoneInput_dropdown-bttn {
  border: none;
}

.react-tel-input .selected-flag:before,
.react-tel-input .selected-flag.open:before {
  box-shadow: none !important;
  border: none !important;
}
.react-tel-input .selected-flag .arrow {
  border-top: 5px solid $black-color !important;
  border-left: 5px solid transparent !important;
  border-right: 5px solid transparent !important;
}

.react-tel-input .selected-flag .arrow.up {
  border-top: none !important;
  border-bottom: 5px solid $black-color !important;
}

.phoneInput_dropdown {
  box-shadow: none !important;
  top: 39.5px;
  left: -0.5px;
  flex-direction: column;
  border-bottom: 1px solid $neutral-gray-color;
  border-left: 1px solid $neutral-gray-color;
  border-right: 1px solid $neutral-gray-color;
  background-color: $white-color;
  padding: 7.5px 0px;
  border-radius: 0px !important;
  margin: 0 !important;

  &::-webkit-scrollbar {
    width: 7px;
  }
  &::-webkit-scrollbar-thumb {
    background: #f5f5f5;
  }
  &::-webkit-scrollbar-track {
    background: $white-color;
  }
}
.country {
  // padding:
  padding: 7.5px 10px;
  cursor: pointer;
}

.country-name {
  @include table;
}
.dial-code {
  font-size: 13px;
}
