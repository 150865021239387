@use "shared" as *;

.notification {
  position: absolute;
  bottom: 5px;
  right: 5px;
  width: 453px;
  display: flex;
  flex-direction: column;
  padding: 16px 16px 32px 20px;
  gap: 32px;
  background-color: $black-color;
  z-index: 10;
}

.notification_header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.notification_header-type {
  display: flex;
  flex-direction: row;
  gap: 4px;

  & > p {
    margin: 0;
    @include body-m;
    font-weight: 400;
    line-height: 106%;
    color: $white-color;
    opacity: 0.6;
    padding-top: 2px;
  }
}

.notification_close-icon {
  width: 16px;
  height: 16px;
  background-image: url("./icons/close.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 16px 16px;
  cursor: pointer;
}

.notification-type_icon {
  width: 20px;
  height: 20px;
  background-size: 20px 20px;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 2px;
}

.warning_icon {
  background-image: url("./icons/warning.svg");
}
.success_icon {
  background-image: url("./icons/success.svg");
}
.info_icon {
  background-image: url("./icons/info.svg");
}

.notification_text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  & > p {
    margin: 0;
    @include hint;
    font-size: 13px;
    color: $white-color;
  }
}

.notification_span {
  font-size: 12px;
  font-style: italic;
  font-weight: 500;
}

.notification_reminder-bttn {
  margin-top: 16px;
  border: 1px solid $white-color;
  padding: 12px 26px 10px 26px;
}

.notification_checkbox-wrapper {
  position: relative;
  display: flex;
  align-items: center;

  & > p {
    margin: 0;
    @include hint;
    font-size: 13px;
    color: $white-color;
    margin-left: 8px;
    margin-top: 3px;
  }
}

.notification_render-info-list{
  padding-left: 15px;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  @include hint;
  font-size: 13px;
  color: $white-color;

}

.gray{
  color: $gray-color;
}