@use "shared" as *;

.billing {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.billing_menu {
  flex: 1.1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 52px;

  &_narrow {
    flex: 1;
  }
}

.billing_section {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0 0 100px 0;

  &_wide {
    flex: 2.25;
    margin: 0;
  }
}

.billing_menu-items {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 150px;

}

.billing_menu-item {
  margin: 0;
  letter-spacing: -0.26px;
  opacity: 1;
  transition: 0.2s;
  cursor: pointer;
  &_active {
    opacity: 0.4;
  }
}

.overview_section {
  display: flex;
  flex-direction: column;
  padding-top: 52px;
  gap: 52px;
}

.overview_section-beta-notion {
  display: flex;
  flex-direction: column;
  gap: 23px;
  width: 95%;
}

.beta-notion-text {
  & > p {
    @include body-mono;
    text-transform: none;
    letter-spacing: -0.26px;
    padding-bottom: 18px;
    margin: 0;
  }
}

.beta-notion-title {
  font-family: "Moderat";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 136%;
  letter-spacing: -0.56px;
  text-transform: none;
  margin: 0;
}

.overview_section_tokens {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.overview_section_tokens-title {
  display: flex;
  flex-direction: row;
  & > p {
    @include body-mono;
    letter-spacing: -0.26px;
    margin: 0;
  }
}

.balance_info {
  cursor: pointer;
  width: 18px;
  height: 18px;
  background-image: url("../icons/info.svg");
  background-size: 17px 17px;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 2px;
  margin-left: 6px;
  transition: all 0.5s;

  &:hover{
    background-image: url("../icons/info_hover.svg");
  }
}

.balance {
  @include h4;
  margin: 0;
}

.buy_tokens-bttn {
  gap: 20px;
  margin-top: 22px;
}

.payment_methods {
  display: flex;
  flex-direction: column;
  gap: 32px;
  overflow-y: scroll;
  padding: 52px 6px 50px 0;

  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 7px;
  }
  &::-webkit-scrollbar-thumb {
    background: #f5f5f5;
  }
  &::-webkit-scrollbar-track {
    background: $white-color;
  }
}

.payment-methods-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.no-payment-methods {
  margin: 0;
  opacity: 0.4;
}

.add-payment-method {
  gap: 20px;
  align-self: baseline;
}

.add_payment-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.payment_method-input_item {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;

  & > p {
    @include body-mono;
    letter-spacing: -0.26px;
    margin: 0;
  }
}

.error-text {
  bottom: -16px;
  left: 0;
  position: absolute;
  @include table;
  color: $error-color;
  margin: 0;
  opacity: 0;
  transition: 0.3s;

  &_active {
    opacity: 1;
  }
}

.payment_method-input {
  width: 100%;
}

.payment_method-input_address {
  display: flex;
  gap: 10px;
}

.card_number_input-wrapper {
  display: grid;
  grid-template-columns: 1fr 68px 65px;
  height: 40px;
  border: 1px solid $neutral-gray-color;

  &_on-focus {
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
  }

  &_error {
    position: relative;
    border: 1px solid $error-color;
  }
  & > input {
    border: none;
    outline: none;
    margin: 10px 0;
    padding: 0px 10px;
    min-width: 30px;

    &::placeholder {
      @include table;
      color: $gray-color;
    }
  }
}
.card_date_input {
  border-right: 1px solid rgba(0, 0, 0, 0.4) !important;
  padding-right: 20px !important;
}
.card_cvc_input {
  padding-left: 20px !important;
}

.payment_method-checkbox_wrapper {
  position: relative;
  display: flex;

  & > p {
    @include table;
    margin: 0;
    margin-left: 24px;
  }
}

.payment-checkbox {
  opacity: 1;
  left: 0;
  top: -2px;
}

.add-payment-bttns-wrapper {
  display: flex;
  flex-direction: row;
  gap: 19px;
  margin-top: 12px;
}

.add-payment-bttn {
  gap: 20px;
}

.payment_card {
  display: flex;
  justify-content: space-between;
  border: 1px solid $neutral-gray-color;
  padding: 20px 23px 18px 22px;
  height: 116px;
}

.payment_card-info {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  & > p {
    margin: 0;
  }
}

.payment_card-number-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.payment_card-number-hiden {
  height: 5px;
  width: 26px;
  background-image: url("../icons/ellipsis.svg");
  background-size: 26px 5px;
  background-repeat: no-repeat;
  background-position: center;
}

.payment_card-number {
  color: $black-color;
  font-family: "Moderat";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 116%; /* 23.2px */
  letter-spacing: -0.4px;
  margin: 0;
}

.payment_card-date {
  @include body-mono;
  letter-spacing: -0.26px;
}

.payment_card-state_default {
  @include body-mono;
  letter-spacing: -0.26px;
  opacity: 0.4;
}

.payment_card-state_not-default {
  @include body-mono;
  letter-spacing: -0.26px;
  text-decoration: underline;
}

.delete_card {
  display: flex;
  align-items: center;
  cursor: pointer;

  & > p {
    transition: 0.3s;
    @include body-mono;
    letter-spacing: -0.26px;
    margin: 0;
  }

  &:hover {
    & > p {
      text-decoration: underline;
    }
  }
}

.payment_card-type {
  display: flex;
  padding: 8px;
  background-color: #d9d9d9;
}

.delete_card-icon {
  width: 18px;
  height: 18px;
  background-image: url("../icons/delete.svg");
  background-size: 18px 18px;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 4px;
}

.right-align {
  align-items: flex-end;
}

.new_payment_method {
  width: 100%;
  border-bottom: 1px solid $gray-color;

  & > p {
    letter-spacing: -0.26px;
    opacity: 0.4;
    margin: 0;
  }
}

.billing_histoty {
  padding-top: 52px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  height: 100%;
  padding-bottom: 130px;

  & > p {
    margin: 0;
  }
}

.billing_histoty-table {
  width: 100%;
  overflow-y: scroll;
  position: relative;

  &::-webkit-scrollbar {
    width: 0;
  }
}

.billing_histoty-table_header {
  position: sticky;
  top: 0px;
  z-index: 2;
  width: 100%;
  height: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  background-color: $light-gray-color;
}

.histoty-table_header-cell {
  @include caption;
  padding: 9px 10px 7px 10px;
  border-right: 1px solid rgba(144, 138, 130, 0.2);
  color: $dark-brown-color;

  &:last-child {
    border: none;
  }
}

.histoty-table_row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  height: 68px;
}

.histoty-table_cell {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  border-bottom: 1px solid $medium-gray-color;
  & > p {
    @include table;
    margin: 0;
  }
}

.invoice_undeline {
  text-decoration: underline;
}

.invoice_status {
  display: flex;
  padding: 6px 8px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  @include table;

  &-paid {
    background-color: #ccffe3;
  }

  &-in_progress {
    background-color: #cce3ff;
  }
}
