@use "shared" as *;

.attachment {
  min-height: 56px;
  display: grid;
  align-items: start;
  grid-template-columns: minmax(112px, 112px) minmax(56px, 56px) minmax(0, 1fr) minmax(46px, 46px);
  border-bottom: 1px solid $medium-gray-color;

  &:hover {
    background-color: $light-blue-color;
  }
}

.attachment__image {
  width: 56px;
  height: 56px;
  object-fit: contain;
  cursor: pointer;
  background-color: white;
}

.attachment__ext {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 100%;
  max-height: 56px;
  background-position: center;
  background-size: 30px 30px;
  background-repeat: no-repeat;
  background-color: white;
}

.ext-fra {
  background-image: url("./icons/rfa.svg");
}

.ext-skp {
  background-image: url("./icons/skp.svg");
}

.ext-lcf {
  background-image: url("./icons/lcf.svg");
}

.ext-rvt {
  background-image: url("./icons/rvt.svg");
}

.ext-3ds {
  background-image: url("./icons/3ds.svg");
}

.ext-c4d {
  background-image: url("./icons/c4d.svg");
}

.ext-dwg {
  background-image: url("./icons/dwg.svg");
}

.ext-jpg {
  background-image: url("./icons/jpg.svg");
}

.ext-pdf {
  background-image: url("./icons/pdf.svg");
}

.ext-adsklib {
  background-image: url("./icons/adsklib.svg");
}

.ext-dae {
  background-image: url("./icons/dae.svg");
}

.ext-ldt {
  background-image: url("./icons/ldt.svg");
}

.ext-ifc {
  background-image: url("./icons/ifc.svg");
}

.ext-mat {
  background-image: url("./icons/mat.svg");
}

.attachment__cell3 {
  display: flex;
  flex-direction: column;
  padding: 10px 10px;
  justify-content: center;
  gap: 10px;
  height: 100%;
}

.attachment__filename {
  @include table;
  text-decoration: underline;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.attachment__note {
  @include table;
  width: 100%;
  resize: none;
  outline: none;
  border: none;
  background: transparent;
  padding: 0px;

  &::placeholder {
    @include table;
    color: $gray-color;
  }

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: $medium-gray-color;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
}

.attachment__remove {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  opacity: 0;

  .attachment:hover & {
    opacity: 1;
  }
}

.group-segment-attachment {
  min-height: 56px;
  display: grid;
  align-items: start;
  grid-template-columns: minmax(224px, 224px) minmax(56px, 56px) minmax(0, 1fr) minmax(137px, 137px);
  border-bottom: 1px solid $medium-gray-color;

  &:hover {
    background-color: $light-blue-color;
  }
}

.group-segment-attachment__image {
  width: 56px;
  height: 56px;
  object-fit: contain;
  cursor: pointer;
  background-color: white;
}

.group-segment-attachment__ext {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 100%;
  max-height: 56px;
  background-position: center;
  background-size: 30px 30px;
  background-repeat: no-repeat;
  background-color: white;
}

.group-segment-attachment__cell3 {
  display: flex;
  flex-direction: column;
  padding: 10px 10px;
  justify-content: center;
  gap: 10px;
  height: 100%;
}

.group-segment-attachment__filename {
  @include table;
  text-decoration: underline;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.group-segment-attachment__note {
  @include table;
  width: 100%;
  resize: none;
  outline: none;
  border: none;
  background: transparent;
  padding: 0px;
  cursor: text;

  &::placeholder {
    @include table;
    color: $gray-color;
  }

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: $medium-gray-color;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
}

.attachment-preview {
  position: fixed;
  width: 100vw;
  height: 100vh;
  inset: 0;
  background-color: #00000080;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 7;
}

.attachment-preview__container {
  display: grid;
  grid-template-columns: auto 30px;
  grid-template-rows: 30px auto;
}

.attachment-preview__exit-button {
  grid-column: 2 / 2;
  grid-row: 1 / 1;
  cursor: pointer;
}

.attachment-preview__image {
  grid-column: 1 / 1;
  grid-row: 2 / 2;
  background-color: $white-color;
  max-height: calc(100vh - 100px);
  max-width: calc(100vw - 100px);
}
