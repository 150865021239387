@use "shared" as *;

.left-side-panel {
    position: absolute;
    inset: 0px;
    background: #00000080;
    z-index: 8;
}

.left-side-panel__container {
    background-color: $white-color;
    position: absolute;
    top: 0;
    left: -1056px;
    bottom: 0;
    z-index: 8;
    width: 1056px;
    display: flex;
    flex-direction: column;
    animation: 500ms forwards opening;
}

@keyframes opening {
    from {
        left: -1056px;
    }
    to {
        left: 0px;
    }
}

.left-side-panel__close-button {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 16px;
    height: 16px;
    cursor: pointer;
}
