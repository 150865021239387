@use "shared" as *;

.upload_header {
  height: 41px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $gray-color;
}

.upload__left-header {
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-grow: 1;
}

.upload__header-logo {
  width: 121px;
  height: 100%;
  background-image: url("./icons/newLogo.svg");
  background-size: 65px 13px;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  position: relative;

  &:hover {
    background-color: $light-gray-color;
  }
}

.upload__header-sketch-menu {
  cursor: pointer;
  width: 122px;
  height: calc(100% + 1px);
  inset: 0px;
  border: none;
  border-bottom: 1px solid $gray-color;
  background-image: url("./icons/newLogo.svg");
  background-size: 65px 13px;
  background-position: 22px center;
  background-repeat: no-repeat;
  outline: none;

  &:hover {
    background-color: $light-gray-color;
  }

  & .select__items {
    width: 143px;
    top: 40px;
    border: 1px solid $gray-color;
  }

  &:focus {
    background-color: $light-gray-color;
  }
}

.upload__header-home {
  width: 47px;
  height: 100%;
  cursor: pointer;
  border-right: 1px solid $gray-color;
  border-left: 1px solid $gray-color;

  &:hover {
    background-color: $light-gray-color;
  }

  &_disabled {
    pointer-events: none;
  }
}

.upload__header-home-icon {

  position: relative;
  width: 45px;
  height: 100%;
  background-image: url("./icons/home-icon.svg");
  background-size: 13.125px 14.782px;
  background-position: center;
  background-repeat: no-repeat;
  padding: 10px 12px;
  transition: all 0.3s;

  &_disabled {
    background-image: url("./icons/home-icon_filled.svg");
  }
  
  &:hover {
    background-image: url("./icons/home-icon_filled.svg");
  }
}

.upload__header-state {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.size-span {
  white-space: pre;
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  opacity: 0;
  min-width: 5px;
  user-select: none;
  vertical-align: top;
  @include body-mono;
  text-transform: uppercase;
}

.upload__register {
  @include body-mono;
  cursor: pointer;
  padding: 0 28px;
  display: flex;
  align-items: center;
  text-decoration: underline;

  &:hover {
    background-color: $light-gray-color;
    text-decoration: none;
  }
}

.sketch__header-register {
  cursor: pointer;
  @include body-mono;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 25px;
  text-decoration: underline;
  transition: all 0.3s;

  &:hover {
    background-color: $light-gray-color;
    text-decoration: none;
  }
}

.settings_title-input {
  position: relative;
  box-sizing: border-box;
  font-size: 14px;
  padding: 0px 4px;
  display: inline-block;
}

.settings_title-input>input {
  height: 100%;
  background-color: $light-gray-color;
  color: inherit;
  top: 0;
  left: 0;
  font-size: inherit;
  line-height: inherit;
  padding: inherit;
  position: absolute;
  box-sizing: border-box;
  @include body-mono;
  border: none;
}

.settings_title-input>input:focus {
  outline: none;
}
