@use "shared" as *;

.sketch-list {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 10px 8px 0;
  cursor: pointer;
  transition: 0.3s;

  & > p {
    @include h3;
    margin: 0;
    opacity: 0.4;
  }

  &:hover {
    & > p {
      opacity: 1;
    }
  }

  &__selected {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 8px 10px;
    cursor: pointer;
    transition: 0.3s;
    background-color: $light-gray-color;

    & > p {
      @include h3;
      margin: 0;
    }

    .sketch-list__icon {
      display: flex;
    }
  }
}

.sketch-list__icons_wrapper {
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 2;
}

.sketch-list__icon {
  position: relative;
  width: 34px;
  height: 42px;
  background-size: 18px 18px;
  background-position: center;
  background-repeat: no-repeat;
  display: none;
  transition: 0.3s;

  &:hover {
    background-color: $neutral-gray-color;
  }

  &_hide {
    display: none !important;
  }
}

.open_icon {
  background-image: url("../icons/open.svg");
}

.rename_icon {
  background-image: url("../icons/rename.svg");
}

.copy_icon {
  background-image: url("../icons/copy.svg");
}

.delete_icon {
  background-image: url("../icons/delete.svg");
}

.sketch-list__name-input-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 10px;
  cursor: pointer;
  transition: 0.3s;
  background-color: $white-color;
  border: 1px solid $medium-gray-color;
}

.sketch-list__name-input-wrapper>input{
  all: unset;
  border: 0px;
  @include h3;
  margin: 0;
  width: 100%;
}
