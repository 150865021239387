@use "shared" as *;

.mail_preferences {
  margin-top: 52px;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.mail_preferences-menu {
  flex: 1.1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &>p{
    cursor: pointer;
    margin: 0;
    opacity: 0.4;
  }
}

.mail_preferences_items{
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 32px;
    padding-top: 52px;
}


.mail_preferences_toggle{
    display: flex;
    flex-direction: row;
    gap: 37px;

    &>p{
        margin: 0;
        @include body-mono-reg;
        line-height: normal;
        font-size: 13px;
    }
}
