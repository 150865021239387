@use "shared" as *;

.home_wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  position: relative;
  overflow-x: hidden;
}

.home_elements_wrapper {
  flex: 1;
  display: grid;
  grid-template-columns: 1fr 2.3fr;
  padding: 112px 30px 0;
  gap: 46px;
}

.home_prototypes_list-wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
  flex: 1;
}

.home_prototypes_list-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 9px;
  border-bottom: 1px solid $gray-color;
  height: 24px;

  &>p {
    @include body-mono;
    opacity: 0.4;
    margin: 0;
  }
}

.list-header_create-new {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.create-new_add {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  &>p {
    @include body-mono;
    margin: 0;
    // opacity: 0.4;
  }

  &:hover {
    &>p {
      text-decoration: underline;
    }
  }
}

.create-new_add-icon {
  width: 20px;
  height: 20px;
  background-image: url("./icons/add.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  margin: 0 0 3px 4px;
}

.create-new_info-icon {
  cursor: pointer;
  margin-left: 10px;
  width: 18px;
  height: 18px;
  background-image: url("./icons/info.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 18px 18px;
  margin-bottom: 3px;
  transition: all 0.5s;

  &:hover {
    background-image: url("./icons/info_hover.svg");
  }
}

.home_prototypes_list {
  width: 100%;
  max-height: 77vh;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 0px;
  }
}

.home_prototypes_preview-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 9px;
  border-bottom: 1px solid $gray-color;
  height: 24px;

  &>p {
    @include body-mono;
    opacity: 0.4;
    margin: 0;
  }
}

.home_prototypes_preview-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 32px;
}

.home_prototypes_preview {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 92px;
}

.prototype_preview {
  display: flex;
  flex-direction: row;
  gap: 30px;
}

.prototype_preview-image {
  cursor: pointer;
  border: 1px solid $gray-color;
  height: 162px;
  width: 162px;
  position: relative;
}

.prototype_preview-image-segments {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.prototype_preview-info {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.prototype_preview-info-date {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.preview-info-date-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 82px;

  &>p {
    margin: 0;
  }
}

.preview-info-date-title {
  opacity: 0.4;
}

.prototype_preview-open {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  transition: 0.3s;

  &>p {
    margin: 0;
    transition: 0.3s;
  }

  &:hover {
    &>p {
      text-decoration: underline;
    }
  }
}

.prototype_open-icon {
  width: 18px;
  height: 18px;
  background-image: url("./icons/open.svg");
  background-size: 18px 18px;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 4px;
}

.renders_wrapper {
  display: flex;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.renders_header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 9px;
  border-bottom: 1px solid #aaa8a5;
  height: 24px;

  &>p {
    @include body-mono;
    opacity: 0.4;
    margin: 0;
  }
}

.renders_header-dowload-dropdown {
  cursor: pointer;
  display: flex;
  flex-direction: row;

  &:hover {
    &>p {
      text-decoration: underline;
    }
  }
}

.renders-box {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.no_renders {
  @include body-mono;
  opacity: 0.2;
}

.download_dropdown-wrapper {
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;

  &>p {
    margin: 0;
  }

  &:hover {
    &>p {
      text-decoration: underline;
    }
  }
}

.download_icon {
  width: 20px;
  height: 20px;
  background-image: url("./icons/download.svg");
  background-size: 20px 20px;
  background-position: center;
  background-repeat: no-repeat;
}

.dropdow_arrow {
  width: 11px;
  height: 8px;
  background-image: url("./icons/dropdown_arrow.svg");
  background-size: 11px 8px;
  background-position: center;
  background-repeat: no-repeat;
}

.dropdow_arrow.active {
  transform: rotate(180deg);
}

.download-menu {
  top: 23px;
  right: 0px;
  z-index: 5;
  position: absolute;
  width: 125px;
  padding: 20px 0;
  border: 1px solid $gray-color;
  background: $white-color;
  display: flex;
  flex-direction: column;
}

.download-menu>p {
  margin: 0;
  cursor: pointer;
  padding: 10px 0 10px 25px;

  &:hover {
    background-color: $light-gray-color;
  }
}

.disabled_menu-item {
  opacity: 0.4;
  pointer-events: none;
}

.loading-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $white-color;
  height: 100vh;
  width: 100vw;
  position: relative;
  z-index: 10;
  top: 0;
  left: 0;
}