@use "shared" as *;

.help__high-light {
  position: absolute;
  bottom: 0px;
  right: 0px;
  padding: 20px;
  background-color: $white-color;
}

.help_icon {
  z-index: 2;
  cursor: pointer;
  background-color: $light-gray-color;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;


  &:hover {
    background-color: $black-color;
    .help_icon__question_mark{
      filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(181deg) brightness(104%) contrast(102%);
    }
  }
}

.help_icon__question_mark{
  transition: all 0.3s;
  height: 20px;
  width: 20px;
  background-size: 16px 16px;
  background-image: url('./icons/questionMark.svg');
  background-repeat: no-repeat;
  background-position: center;

}

.extended_help-wrapper {
  position: absolute;
  right: -490px;
  height: 100%;
  width: 481px;
  top: 0;
  background-color: $light-gray-color;
  z-index: 5;
  transition: all 0.3s ease-in-out;
}

.extended_help-wrapper.open {
  right: 0;
}

.extended_help-box {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
}

.extended_help {
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  justify-content: space-between;
}

.extended_help-header {
  padding: 10px 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.extended_help-header-left {
  display: flex;
  align-items: center;

  & > p {
    @include body-m;
    margin: 0;
  }
}

.extended_help-book_icon {
  width: 22px;
  height: 22px;
  background-image: url("./icons/book.svg");
  background-size: 22px 22px;
  background-position: center;
  background-repeat: no-repeat;
}

.extended_help-text {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 30px;
  padding: 10px 20px 0;

  & > p {
    @include body-mono-reg;
    margin: 0;
    letter-spacing: 0;
  }

  .extended_help-text_title {
    @include h5;
    padding-top: 50px;
  }
}

.help-text_list-item {
  display: flex;
  flex-direction: row;
  gap: 20px;

  & > p {
    @include body-mono-reg;
    margin: 0;
    letter-spacing: 0;
  }
}

.extended_help-bttns_wrapper {
  padding: 13px 10px 15px 20px;
  position: sticky;
  display: flex;
  justify-content: space-between;
  bottom: 0;
  z-index: 5;
  background: rgba(239, 239, 239, 0.6);
  backdrop-filter: blur(5.099999904632568px);
  width: 481px;
}

.extended_help-bttns {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.extended_help-bttns_scroll {
  cursor: pointer;
  height: 30px;
  width: 30px;
  background-color: $gray-color;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  // transform: rotate(90deg);
  user-select: none;
  transition: all 0.3s;

  &:hover {
    background-color: $black-color;
    color: $white-color;

    .scroll_arrow_down-icon {
      filter: invert(100%) sepia(3%) saturate(1408%) hue-rotate(249deg)
        brightness(119%) contrast(100%);
    }
  }
}

.scroll_arrow_down-icon {
  transition: all 0.3s;
  height: 6px;
  width: 10px;
  background-image: url("./icons/scroll_arrow_down.svg");
  background-position: center;
  background-repeat: no-repeat;
  
}

.double_arrow_right-icon {
  transition: all 0.3s;
  height: 10px;
  width: 11px;
  background-image: url("./icons/double_arrow_right.svg");
  background-position: center;
  background-repeat: no-repeat;
  filter: invert(100%) sepia(3%) saturate(1408%) hue-rotate(249deg)
  brightness(119%) contrast(100%);
}

.extended_help-bttns_scroll.active {
  opacity: 1;
  pointer-events: all;
}

.extended_help-bttns_close {
  user-select: none;
  cursor: pointer;
  height: 30px;
  width: 30px;
  background-color: $black-color;
  color: $white-color;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;

  &:hover {
    background-color: $gray-color;
    color: $black-color;
    .double_arrow_right-icon{
      filter: none;
    }
  }
}

.io-buro_link {
  margin: 0;
  @include body-mono;
  text-transform: none;
  font-weight: 400;
  text-decoration-line: underline;
  cursor: pointer;
}

.extended_help_home-image {
  margin-top: 8px;
  width: 441px;
  height: 244px;
  background-image: url("./icons/home_image.png");
  background-size: 441px 244px;
}

.beta {
  padding: 2px 4px 3px 4px;
  background-color: $gray-color;
  color: $black-color;
  font-family: "Moderat";
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 108%;
  letter-spacing: -0.22px;
  text-transform: uppercase;
}

.extended_help_home_link-wrapper {
  cursor: pointer;
  display: flex;
  align-items: center;

  & > p {
    @include body-mono;
    margin: 0;
    transition: 0.3s;
    text-decoration: underline;
  }
}

.home_link-wrapper-icon {
  width: 20px;
  height: 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  margin-bottom: 1px;
}

.note_icon {
  background-image: url("./icons/note.svg");
}

.tutorial_icon {
  background-image: url("./icons/tutorial.svg");
}

.extended_help_home_quick-start {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.section_title {
  @include body-m;
  margin-bottom: 13px;
  margin: 0;
}

.quick-start_item {
  display: flex;
  gap: 10px;

  & > p {
    @include body-mono-reg;
    letter-spacing: 0;
    margin: 0;
  }
}

.highlight-span {
  @include body-mono-reg;
  letter-spacing: 0;
  font-weight: 500;
}

.dont_show {
  display: flex;
  align-items: center;
  gap: 8px;

  & > p {
    margin: 0;
    @include body-mono-reg;
    letter-spacing: 0;
  }
}

.dont_show_checkbox {
  cursor: pointer;
  height: 17px;
  width: 17px;
  background-color: $white-color;
  border: 1px solid $neutral-gray-color;
}

.dont_show_checkbox-arrow {
  width: 15px;
  height: 15px;
  background-image: url("./icons/check.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 11px 11px;
}

.extended_help_upload {
  padding: 50px 20px 12px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  & > p {
    @include body-mono-reg;
    text-transform: none;
    margin: 0;
    letter-spacing: 0;
  }
}

.extended_help_upload-example {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  & > p {
    margin: 0;
    @include hint;
    font-size: 13px;
    opacity: 0.6;
    letter-spacing: 0;
  }
}

.extended_help_upload-example_image {
  width: 100%;
  height: 244px;
  background-color: $white-color;
}

.extended_help-read_more {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 48px 20px 20px;
}

.read_more_wrapper {
  display: grid;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 20px;
  row-gap: 24px;
}

.read_more-item {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 15px;

  & > p {
    @include body-mono;
    margin: 0;
    transition: 0.3s;
    max-width: 73%;
    letter-spacing: 0;
  }

  &:hover {
    & > p {
      text-decoration: underline;
    }
  }
}

.read_more-item-icon {
  width: 40px;
  height: 40px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 40px 40px;
}

.account_icon {
  background-image: url("./icons/account.svg");
}

.choosing_image_icon {
  background-image: url("./icons/choosing_image.svg");
}

.create_masks_icon {
  background-image: url("./icons/choosing_image.svg");
}

.describe_icon {
  background-image: url("./icons/describe.svg");
}

.group_icon {
  background-image: url("./icons/group.svg");
}

.mask_selection_icon {
  background-image: url("./icons/mask_selection.svg");
}

.rendering_icon {
  background-image: url("./icons/rendering.svg");
}

.what_is_icon {
  background-image: url("./icons/what_is.svg");
}

.interface_icon {
  background-image: url("./icons/interface.svg");
}

.help-loading-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $white-color;
  height: 100vh;
  width: 100vw;
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
}
