@use "shared" as *;

.account_cover {
  display: flex;
  position: absolute;
  inset: 0px;
  z-index: 3;
}

.account_wrapper {
  background-color: $white-color;
  position: absolute;
  top: 0;
  left: -100%;
  bottom: 0;
  z-index: 8;
  width: 1056px;
  display: flex;
  transition: all 0.5s ease-in-out;

  &_open {
    left: 0%;
  }
}
.account {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0px 52px;
}

.account__exit-button {
  width: 16px;
  height: 16px;
  background-image: url("./icons/cross.svg");
  background-size: contain;
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 15px;
}

.account-title {
  @include h5;
  text-transform: none;
  margin: 0;
  padding: 52px 0 52px 52px;
}

.account_menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $gray-color;
}

.account_menu-items {
  display: flex;
  column-gap: 34px;
}

.account_menu-item {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  opacity: 1;
  transition: 0.1s;

  &_active {
    opacity: 0.4;
    cursor: default
  }

  & > p {
    @include body-mono;
    letter-spacing: -0.26px;
    margin: 0;
  }
}

.account_menu-item-icon {
  width: 16px;
  height: 16px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 16px 16px;
  margin-bottom: 4px;
}

.personal_icon {
  background-image: url("./icons/personal.svg");
  background-size: 14px 14px;
}

.billing_icon {
  background-image: url("./icons/billings.svg");
}

.mail_icon {
  background-image: url("./icons/mail.svg");
}

.logout_icon {
  background-image: url("./icons/logout.svg");
}

.account_menu-logout {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;

  & > p {
    @include body-mono;
    letter-spacing: -0.26px;
    margin: 0;
  }
}

.fake_cover {
  position: absolute;
  z-index: 6;
  width: 100%;
  height: 100%;
  background: #00000080;
  display: none;

  &_open {
    display: flex;
  }
}
