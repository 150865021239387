@use "shared" as *;

.products-attach-wrapper {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.products-attach {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 36px 19px;
  height: 100%;
  padding-bottom: 100px;
  overflow-y: scroll;
}

.products-attach::-webkit-scrollbar {
  display: none;
}

.products-attach__container {
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 20px;
  width: 293px;
  height: 295px;
  border: 1px solid $neutral-gray-color;
}

.products-attach__model-image-wrapper {
  height: 180px;
  width: 100%;
}

.products-attach__model-image {
  width: 100%;
  height: 180px;
  object-fit: contain;
}

.products-attach__model-description {
  @include table;
  line-height: normal;
}

.products-attach__model-info-wrapper {
  padding: 0 16px 20px 16px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.products-attach__model-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  gap: 5px;
}

.products-attach__model-designed {
  display: flex;
  flex-direction: column;

  &>p {
    margin: 0;
  }
}

.model-designed_by {
  @include body-mono;
  letter-spacing: -0.26px;
  opacity: 0.4;
}

.products-attach_descr {
  padding: 32px 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  &>p {
    @include body-mono-reg;
    margin: 0;
    max-width: 600px;
    letter-spacing: 0px;
  }
}

.read_more {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;

  &>p {
    @include body-mono;
    margin: 0;
  }

  &:hover {
    &>p {
      text-decoration: underline;
    }
  }
}

.read_more-icon {
  width: 20px;
  height: 20px;
  background-image: url("../icons/readMore.svg");
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-position: center;
}