@use "shared" as *;

.segment__container {

  &:hover:not(&_selected):not(&_unhover),
  &_hovered {
    background-color: $light-blue-color;
  }

  &:hover .segment__readonly-cell-container {
    display: flex;
  }

  &_dragging {
    opacity: 0.6;
  }

  &_selected {
    background-color: $coconut-gray-color;
  }
}

.segment {
  position: relative;
  display: grid;
  grid-template-columns: 58px 54px 31px 1fr 40px 42px 36px;
  grid-template-rows: 56px 1fr;
}

.segment__cell1 {
  display: flex;
  align-items: center;
  gap: 9px;
}

.segment__drag-handler-container {
  width: 22px;
  height: 22px;
  transform: translate(5px, 0px);
}

.segment__cell2 {
  @include table;
  display: flex;
  align-items: center;
  line-height: normal;
}

.segment__color {
  width: 10px;
  height: 10px;
  margin: 0px 4px 0px 4px;
  border-radius: 50%;
  cursor: pointer;

  &_active {
    border: 2px solid $blue-color;
    box-sizing: content-box;
    margin: 0px 2px 0px 2px;
  }
}

.segment__rendering-cell {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.segment__render-icon {
  cursor: pointer;

}

.segment__rendering-icon {
  animation: spin 1s infinite linear;
}

.segment__cell3 {
  padding: 12px 30px 12px 10px;
  display: flex;
  align-items: center;
  background-color: inherit;
  cursor: text;
  grid-row: 1 / 3;
  grid-column: 4 / 4;
  border-bottom: 1px solid $medium-gray-color;

  &:focus-within {
    border-bottom: 1px solid $black-color;
  }
}

.segment__description {
  @include table;
  width: 100%;
  resize: none;
  outline: none;
  border: none;
  background: transparent;

  &::placeholder {
    @include table;
    color: $gray-color;
  }

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: $medium-gray-color;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
}

.segment__cell4 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.segment__open-attachments {
  width: 44px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  cursor: pointer;

  &:hover {
    background-color: $gray-color;
  }
}

.segment__cell4-triangle {
  cursor: pointer;
  transform: translate(0px, -1.5px);

  &_opened {
    transform: translate(0px, -1.5px) rotate(180deg);
  }
}

.segment__cell5 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.segment__add-attach-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  cursor: pointer;

  &:not(&_disabled):hover {
    background-color: $gray-color;
  }

  &_disabled {
    opacity: 0.2;
    cursor: default;
  }
}

.segment__cell6 {
  display: flex;
  align-items: center;
  padding-left: 2px;
}

.segment__delete-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  cursor: pointer;
  opacity: 0;

  &:hover {
    opacity: 1;
    background-color: $gray-color;
  }

  &_disabled {
    cursor: default;
    pointer-events: none;
  }
}

.segment__bottom_cell {
  border-bottom: 1px solid $medium-gray-color;
}

.segment__readonly-cell-container {
  display: none;
  align-items: center;
  justify-content: flex-end;
  grid-row: 1 / 3;
  grid-column: 4 / 5;
}

.segment__readonly-cell {
  @include table;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 10px;
  gap: 6px;
  width: 280px;
  height: 99%;
  background: linear-gradient(90deg, rgba(243, 252, 255, 0.00) 0%, rgba(243, 252, 255, 0.80) 49.66%, #F3FCFF 100%);
}

.segment__attachments {
  overflow: hidden;
  transition: height 200ms ease-out;
}