@use "shared" as *;

.render__element-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 169px;
  height: 168px;
  background-color: $gray-color;
  cursor: pointer;
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    transition: background-color 0.3s;
  }

  &:hover::after {
    background-color: rgba(0, 0, 0, 0.6);
  }

  &:hover {
    .render__element-settings {
      height: 0;
      padding: 0px 10px;
    }
    
    .render__checkbox {
      opacity: 1;
    }
  }
}

.render__element-img {
  height: 100%;
  flex-shrink: 0;
}

.render__element-settings {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-direction: column;
  gap: 15px;
  z-index: 3;
  padding: 16px 10px;
  transition: all 0.3s ease-in-out;

  & > p {
    color: rgba(255, 255, 255, 0.7);
    transition: 0.3s;
    margin: 0;
    &:hover {
      color: $white-color;
    }

    &:last-child{
      margin-bottom: 10px;
    }
  }
}

.render__checkbox {
  top: 10px;
  right: 10px;
  z-index: 3;
  position: absolute;
  opacity: 0;
  transition: 0.3s;
  width: 16px;
  height: 16px;
  background-color: $white-color;
  display: flex;
  align-items: center;
  justify-content: center;
}

.render__checkbox_selected {
  width: 11px;
  height: 11px;
  background-image: url("../icons/check.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 11px 11px;
}

.render__checkbox_active {
  opacity: 1;
}

.render-preview {
  position: fixed;
  width: 100vw;
  height: 100vh;
  inset: 0;
  background-color: #00000080;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
}

.render-preview__container {
  display: grid;
  grid-template-columns: auto 30px;
  grid-template-rows: 30px auto;
}

.render-preview__exit-button {
  grid-column: 2 / 2;
  grid-row: 1 / 1;
  cursor: pointer;
}

.render-preview__image {
  grid-column: 1 / 1;
  grid-row: 2 / 2;
  background-color: $white-color;
  max-height: calc(100vh - 100px);
  max-width: calc(100vw - 100px);
}
