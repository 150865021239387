@use "shared" as *;

.drawing {
  flex-basis: calc(50% - 122px);
  position: relative;
  background-color: $white-color;
  background-image: url("./icons/cross.svg");
  border-right: 1px solid $gray-color;
  border-left: 1px solid $gray-color;
  overflow: hidden;
}

.drawing__canvas {
  position: absolute;
  inset: 0px;
}

.drawing__title {
  position: absolute;
  top: 24px;
  left: 20px;
  color: #1a1c1f;
}

.drawing__prototype-loader {
  position: absolute;
  inset: 0px;
  bottom: 103px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 7;
  display: flex;
  justify-content: center;
  align-items: center;
}

.drawing__prototype-loader-spinner {
  background-image: url(./icons/loader.gif);
  width: 70px;
  height: 70px;
  background-size: contain;
}

.drawing__footer {
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.drawing__footer-bar {
  display: flex;
  height: 67px;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px 0 20px;
  width: 100%;
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(5.099999904632568px);
  z-index: 7;
}

.drawing__footer-bar-left {
  display: flex;
  align-items: center;
  gap: 26px;
  justify-content: flex-start;
  width: calc(100% - 98px);
}

.drawing__footer-background-selector {
  display: flex;
  border: 1px solid $neutral-gray-color;
  background-color: $white-color;
  cursor: pointer;

  &_disabled {
    opacity: 0.2;
    pointer-events: none;
    cursor: default;
  }
}

.drawing__footer-background-option {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  padding: 6px 12px;

  &_selected {
    background-color: $coconut-gray-color;
  }
}

.drawing__footer-background-option-text {
  transform: translate(0px, 1px);
}

.drawing__footer-replace-button-container {
  display: flex;
  align-items: center;
  width: calc(100% - 203px);
}

.drawing__footer-replace-button-file-name {
  @include table;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 16px;
}

.drawing__footer-replace-button-file-ext {
  @include table;
}

.drawing__footer-replace-button-file-close-button {
  margin-left: 4px;
  cursor: pointer;
  flex-shrink: 0;
}

.drawing__zoom-buttons {
  display: flex;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;

  &_disabled {
    opacity: 0.2;
    pointer-events: none;
  }
}

.drawing__zoom-plus {
  margin-left: 8px;
  cursor: pointer;

  &:hover {
    background-color: $coconut-gray-color;
  }
}

.drawing__zoom-minus {
  cursor: pointer;

  &:hover {
    background-color: $coconut-gray-color;
  }
}

.drawing__zoom-fit {
  @include tools;
  width: 36px;
  height: 24px;
  letter-spacing: -0.13px;
  margin-left: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(0px, 1px);
  cursor: pointer;

  &:hover {
    background-color: $coconut-gray-color;
  }
}

.drawing__footer-state {
  position: relative;
  @include caption;
  text-transform: none;
  letter-spacing: -0.11px;
  font-weight: 400;
  display: flex;
  align-items: center;
  padding: 0px 56px 0px 20px;
  height: 36px;
  color: #575757;
  background: $light-gray-color;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.drawing__footer-ai-spin {
  animation: spin 2s linear infinite;
}

.drawing__footer-ai-circle {
  position: absolute;
  right: 0px;
}

.drawing__footer-ai-state {
  display: flex;
  align-items: center;
  gap: 9px;
  flex-shrink: 0;
}

.drawing__footer-ai-state-spin {
  animation: spin 2s linear infinite;
}

.drawing__footer-reselect-container {
  display: flex;
  flex-shrink: 0;
  gap: 20px;
  width: 220px;
  justify-content: flex-end;
}

.drawing__footer-reselect {
  text-decoration: underline;
  cursor: pointer;
}

.drawing__footer-items {
  width: 130px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.drawing__zoom-undefined {
  position: absolute;
  width: 100%;
  height: 100%;
  transform-origin: top left;
  z-index: 7;
}

.drawing__undefined-cover {
  position: absolute;
  inset: 50% 0px 0px;
  transform: translate(0px, -50%);
  user-select: none;
  object-fit: contain;
  background-color: rgba(0, 0, 0, 0.5);
}

.drawing__canvas-undefined {
  position: absolute;
  inset: 0px;
  z-index: 10;
}

.upload__frame-container {
  position: absolute;
  inset: 0px;
  bottom: 36px;
  background-color: $white-color;
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &_dragging {
    background-color: $light-blue-color;
  }
}

.upload__frame {
  background-color: $white-color;
  position: relative;
  width: calc(100% - 40px);
  height: 400px;
  z-index: 5;
}

.upload__browse {
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  width: 50%;
  background-color: $black-color;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: width 0.3s ease-in-out;
  cursor: pointer;
  overflow: hidden;

  &:hover {
    width: 100%;

    &~.upload__sample {
      width: 0%;
    }
  }
}

.upload__browse-title {
  @include body-mono;
  color: $white-color;
}

.upload__browse-button {
  @include body-mono;
  align-self: center;
  color: $white-color;
}

.upload__browse-note {
  @include table;
  color: $white-color;
  opacity: 0.4;
}

.upload__sample {
  position: absolute;
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 50%;
  transition: width 0.3s ease-in-out;
  cursor: pointer;
  background: #FFFFFF url('./images/sample.png') no-repeat 50% 50%;
  overflow: hidden;

  &:hover {
    width: 100%;

    &~.upload__browse {
      width: 0%;
    }
  }
}

.upload__sample-background {
  background-color: #3030307D;
  position: absolute;
  inset: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 32px;
}

.upload__sample-title {
  @include body-mono;
  color: $white-color;
}

.upload__sample-button {
  @include body-mono;
  width: 168px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $black-color;
  color: $white-color;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.upload__frame-checked {
  @include table;
  color: $error-color;
  position: absolute;
  bottom: -25px;
}