@use "shared" as *;

.save-before-closing {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 52px;
    gap: 32px;
}

.save-before-closing__question {
    @include h4;
}

.save-before-closing__buttons {
    display: flex;
    gap: 10px;
}
