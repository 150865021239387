@use "shared" as *;

.sketch {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.sketch__header {
  height: 41px;
  display: flex;
  align-items: stretch;
  border-bottom: 1px solid $gray-color;
  flex-shrink: 0;
}

.sketch__header-logo {
  width: 122.5px;
  border-right: 1px solid $gray-color;
  background-image: url("./icons/logo.svg");
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
  cursor: pointer;
}

.sketch__header-demo-research {
  cursor: pointer;
  @include body-mono;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 25px;
  border-right: 1px solid $gray-color;
}

.sketch__header-demo-research-hint > p {
  @include tooltip;
  margin: 0;
}


.sketch__header-title {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;

  & > *:nth-child(2) {
    margin: 0px 16px;
  }
}

.sketch__continue-button {
  border: none;
  align-self: center;
  height: 100%;
  background-color: $white-color;
  padding-top: 2px;
}

.sketch__body {
  position: relative;
  display: flex;
  height: calc(100vh - 41px);
}

.sketch-left-bar {
  width: 122px;
  flex-shrink: 0;
}

.sketch-main {
  display: flex;
  flex-grow: 1;
}

.sketch-list {
  flex-grow: 1;
}

.sketch__back-modal {
  position: absolute;
  inset: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 40px;
}

.back-modal {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
  width: 606px;
}
.back-modal__exit-button-container {
  align-items: flex-end;
  display: flex;
  height: 40px;
  justify-content: flex-end;
  padding: 0 24px;
}

.back-modal__title {
  color: #000;
  font-family: "Moderat";
  font-size: 22px;
  font-weight: 500;
  letter-spacing: -0.44px;
  line-height: 116%;
  margin: 10px 93px 0;
  text-transform: none;
}

.back-modal__text {
  @include body-mono;
  margin: 32px 93px 0;
  text-transform: none;
}

.back-modal > h3 {
  font-family: "Moderat";
  text-transform: none;
  font-weight: 500;
  font-size: 22px;
  line-height: 116%;
  letter-spacing: -0.44px;
  color: #000000;
  text-align: center;
  font-size: 29px;
}

.back-modal__buttons-container {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  margin: 48px 40px 0;
}
.back-modal__buttons-container > button {
  @include button;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 20px 10px 20px;
  border: 1px solid $black-color;
  background-color: $white-color;
  color: $black-color;
  transition: 0.3s;
}

.back-modal__buttons-container > button:hover {
  background-color: $black-color;
  color: $white-color;
}

.back-modal-buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.back-modal-buttons > button {
  @include button;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 20px 10px 20px;
  border: 1px solid $black-color;
  background-color: $white-color;
  color: $black-color;
  transition: 0.3s;
}

.back-modal-buttons > button:hover {
  background-color: $black-color;
  color: $white-color;
}
