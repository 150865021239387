@use "shared" as *;

.attachments__container {

  position: absolute;
  right: -100%;
  height: 100%;
  width: 100%;
  top: 0;
  background-color: #ffffff;
  z-index: 2;
  transition: all 0.3s ease-in-out;
  box-shadow: -2px 18px 20px 0px rgba(0, 0, 0, 0.08);
}

.attachments__container.open {
  right: 0;
  box-shadow: none;
}

.attachments {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 20px 20px 41px 20px;
  display: flex;
  flex-direction: column;
}

.attachments__close-button {
  width: 20px;
  height: 20px;
  background-image: url("./icons/close.svg");
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  margin-bottom: 2px;
}

.attachments__title {
  @include h3;
}

.attachments__menu {
  margin-top: 35px;
  display: flex;
  justify-content: flex-start;
  gap: 44px;
  height: 26px;
  padding-bottom: 10px;
  border-bottom: 1px solid #c4c2bf;
}

.attachments__menu-button {
  @include button;
  color: $black-color;
  opacity: 0.4;
  cursor: pointer;
  position: relative;
  transition: 0.3s;

  &:hover {
    opacity: 1;
  }

  &_active,
  &_active:hover {
    opacity: 1;

    &::before {
      opacity: 1 !important;
    }
  }

  &::before {
    content: "";
    position: absolute;
    height: 1px;
    width: 100%;
    background-color: black;
    bottom: -11px;
    opacity: 0;
    transition: 0.3s;
  }
}

.is_beta {
  padding-right: 25px;

}

.menu-button__beta-marker {
  position: absolute;
  bottom: 7px;
  right: 0px;
  font-size: 9px;
}

.attachments__apply-button {
  width: 213px;
}

.attachments_header {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
}

.attachments_header-close {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  transition: 0.3s;

  &>p {
    transition: 0.3s;
    margin: 0;
  }

  &:hover {
    &>p {
      text-decoration: underline;
    }
  }
}

.attachments_header-segment_info {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.attachments_header-icon {
  width: 40px;
  height: 40px;
  background-image: url("./icons/addAttach.svg");
  background-size: 40px 40px;
  background-position: center;
  background-repeat: no-repeat;
}

.header-segment_info-color {
  display: flex;
  flex-direction: column;
  height: 40px;
  justify-content: space-between;

  &>p {
    margin: 0;
  }
}

.info-color {
  display: flex;

  &>p {
    margin: 0 0 0 4px;
    opacity: 0.5;
  }
}

.attachments__apply_footer {
  position: sticky;
  padding: 16px 0 41px;
  width: 100%;
  bottom: 0;
  z-index: 2;
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(5.099999904632568px);
}