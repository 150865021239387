@use 'shared' as *;

.match-definition-hint {
    @include hint;
    font-size: 12px;
    position: absolute;
    padding: 16px 5px 16px 10px;
    width: 280px;
    max-height: 50vh;
    background-color: $white-color;
    z-index: 10;
    box-shadow: 0px 1px 3.4px 0px rgba(0, 0, 0, 0.10);
    transition: transform 0.3s;
}

.match-definition-hint__icon {
    flex-shrink: 0;
}

.match-definition-hint__description-container {
    display: flex;
    gap: 2px;
}

.match-definition-hint__description {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.match-definition-hint__attachment {
    display: flex;
    gap: 5px;
    flex-direction: column;
    margin-left: 29px;
    margin-top: 5px;
}

.match-definition-hint__attachment-file {
    display: flex;
    gap: 2px;
}

.match-definition-hint__attachment-note {
    display: flex;
    margin-left: 24px;
}

.match-definition-hint__help {
    margin-top: 16px;
    margin-left: 4px;
    color: $gray-color;
}